import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import axios from 'axios'
import { hasIn } from 'lodash'
import uuid from 'uuid/v4'
import { getAnonymousId, identifyUser } from '@components/integrations/Segment'
import { getRegionZone } from '@helpers/geo-location'

const regionInPR = getRegionZone().region === 'PR'

const TIMEOUT_LENGTH = 5000

const log = (logType, id) => {
  sentryLogger({
    captureMessage: {
      type: 'text',
      message: logType,
      level: levels.info,
    },
    configureScope: {
      type: setExtra,
      transactionId: id,
    },
  })
}

// TODO: Change to furniture.com API? Right now browser will show auth-token.
// Token exposure isn't an issue if we're whitelisting URLs for address lookup
// eslint-disable-next-line
export let fetchAddressLookup = async address => {
  const transactionId = uuid()
  if (!address) {
    return null
  }
  log(sentryMessages.QasNetworkRequestMade, transactionId)
  try {
    let edqResponse = null
    edqResponse = await axios.get(
      `https://api.edq.com/capture/address/v2/search?country=${
        regionInPR ? 'USA' : 'USA'
      }&take=10&shoppingState=&query=${address}`,
      {
        headers: {
          'auth-token': process.env.GATSBY_QAS_AUTH_TOKEN,
        },
        timeout: TIMEOUT_LENGTH,
      },
    )
    return edqResponse.data
  } catch (error) {
    if (error.message.includes('timeout')) {
      log(sentryMessages.QasNetworkTimeout, transactionId)
    } else {
      log(sentryMessages.QasFailure, transactionId)
    }
    throw error
  }
}

export const fetchAddressFormat = async id => {
  const transactionId = uuid()
  if (!id) {
    return null
  }
  log(sentryMessages.QasNetworkRequestMade, transactionId)
  try {
    let edqResponse = null
    edqResponse = await axios.get(
      `https://api.edq.com/capture/address/v2/format?country=${regionInPR ? 'USA' : 'USA'}&id=${id}`,
      {
        headers: {
          'auth-token': process.env.GATSBY_QAS_AUTH_TOKEN,
        },
        timeout: TIMEOUT_LENGTH,
      },
    )
    let address = {}
    if (hasIn(edqResponse, 'data.address')) {
      address = edqResponse.data.address.reduce((addressObj, lineItem) => {
        const [value] = Object.values(lineItem)
        addressObj[Object.keys(lineItem)[0]] = value
        return addressObj
      })
    }
    return address
  } catch (error) {
    if (error.message.includes('timeout')) {
      log(sentryMessages.QasNetworkTimeout, transactionId)
    } else {
      log(sentryMessages.QasFailure, transactionId)
    }
    throw error
  }
}

export const fetchPromotions = body =>
  axios.post(`${process.env.GATSBY_PROMOTIONS_SERVICE_URL}/get_pricing/`, body).then(response => response.data)

/* Order Service */
export const getOrder = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/getOrder/`, body).then(response => response.data)

export const getToken = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/v2/token/`, body).then(response => response.data)

export const createOrder = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/createOrder/`, body).then(response => response.data)

export const updateLineItems = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/updateLineItems/`, body).then(response => response.data)

export const updateAddress = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/updateAddress/`, body).then(response => response.data)

export const updateDelivery = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/updateDelivery/`, body).then(response => response.data)

export const updatePayment = body =>
  axios.post(`${process.env.GATSBY_ORDER_SERVICE_URL}/updatePayment/`, body).then(response => response.data)

export const createBitPayInvoice = body =>
  axios.post(`${process.env.GATSBY_BITPAY_SERVICE_URL}/invoice/`, body).then(response => response.data)

export const placeOrder = (orderId, session, TL_RTG) =>
  axios
    .post(`${process.env.GATSBY_ORDER_SERVICE_URL}/placeOrder/`, { orderId, session, TL_RTG })
    .then(response => response.data)

export const getStoreCartItem = (type, requestObject) => {
  const endPointToCall = type === 'email' ? '/getCartByEmailZip' : '/getCartByIdZip'
  const options = {
    headers: { 'x-api-key': process.env.GATSBY_STORECART_API_KEY },
  }
  return axios
    .post(`${process.env.GATSBY_STORECART_SERVICE_URL}${endPointToCall}`, requestObject, options)
    .then(response => response.data)
}

export const checkSplitElig = (orderNumber, zipCode) =>
  axios
    .get(`${process.env.GATSBY_ORDER_SERVICE_URL}/splitCheck/${orderNumber}/${zipCode}`)
    .then(response => response.data)

export const getAlgoliaRecommendation = body => {
  const options = {
    headers: { 'x-api-key': process.env.GATSBY_ALGOLIA_SERVICE_API_KEY },
  }
  return axios
    .post(`${process.env.GATSBY_ALGOLIA_SERVICE_URL}/recommendations`, body, options)
    .then(response => response.data)
}
